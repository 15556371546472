// Animations

@keyframes seemore_anim{
  0%{
    bottom:0%;
  }
  100%{
    bottom: 13px;
  }
}
// Colours

$azulCreative: #66c2ce;
$amarelinho: #ffc61d;
$white: #fff;

.App-header {
  background: url("Site.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;

  position: relative;
}

.texto-header{
  max-width: 800px;
  
  
}
.text{
  max-width: 700px;
  margin: 2em;
}

.see-more{
  position: absolute;
  bottom: 1%;
  left: 50%;
  text-align: center;
  animation: seemore_anim 0.6s linear 0.5s infinite alternate;
  img{
    max-width: 70%;
  }
}

.sizeable{
  font-size: calc(10px + 1vmin);  
}

.sec_container{
  background: #fff;
  .content{
    margin: 0 auto ;
    h2{
      text-align: center;
      margin: 0;
      font-size: 2em;
      
    }
     div{
       max-width: 100%;
       margin: 0 auto;
     }
     .job-button{
       display: flex;
       flex-flow: column;
       justify-content: center;;

      .job-list{
        display: flex;
        flex-flow: row nowrap;
        list-style: none;
        justify-content: space-around;
        padding: 0;
        li{
          display: flex;
          flex-flow: row;
          justify-content: center;
          align-items: center;
          
          max-width: 280px;
          max-height: 280px;
          transition: 0.3s all;

          &:hover{
            padding: 20px;
            border: 1px solid #111;
            border-radius: 15px

          }
          img{
            max-width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

      }
     }
  }
  .div_container{
    margin: 2em auto;
    height: 90vh;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    .div_content{
      max-width: 900px;
      margin: 2em auto;
      .them{
        margin: 0 1em;
      }
    }
    
  }
  .aboutus{
    background: $azulCreative;
    padding: 5em 2em;
    color: $white;
    .abouttitle{
      max-width: 900px;
      margin: 1em auto;
    }
    .aboutus_content{
      max-width: 900px;
      margin:2em auto;
    }
  }
  .call_to_arms{
    padding: 3em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 3em auto;

    h3{
      font-size: 2em;
      margin: 0;
    }
    p{
      font-size: 1.25em;
    }
    a{
      padding: 1em 1.25em;
      border: 5px solid #111;
      color: #111;
      text-decoration: none;
      transition: 0.3s all ease-out
    }
    a:hover{
      border:5px solid $amarelinho;
      color:$amarelinho;
    }
  }
}
.oportunidade_selector{
  padding: 4em 1em 4em 1em;
}

.close_detail{
  max-width: 30px;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

//==========================Footer=========================

/* start contact */
#master_footer{
  font-size: 1em;
  background: $azulCreative
}

#contact {
padding-top: 40px;
padding-bottom: 7.2rem;
color: white;
}
#contact .section-intro h1 {
color: white;
}
#contact .section-intro h5 {
color: #fff;
font-weight: bold;
}
#contact .section-intro p {
color: rgba(255, 255, 255, 0.7);
}

.contact-info {
display: flex;
flex-flow: column;
justify-content: space-around;
margin: 4.8rem auto 0;
font-size: 1rem;
text-align: center;
}
@media ( min-width: 599px){
.contact-info{
  flex-flow:row;
}
}


.contact-info .collapse {
padding: 0;
}
.contact-info .icon {
width: 100%;
margin-bottom: 2.1rem;
}
.contact-info .icon i {
color: #FFFFFF;
}
.contact-info h5 {
color: #fff;
font-weight: bold;
}

/* end contact */

/** 
* ===================================================================
* 18. footer - (_layout.scss)
*
* ------------------------------------------------------------------- 
*/
.social {
padding: 0;
margin: 0;
display: inline-block;
position: relative;
width: 100%;
}
.social li {
list-style: none;
padding: 0;
margin: 0;
display: inline-block;
}
.social li a {
font-size: 16px;
display: table;
width: 40px;
height: 40px;
margin: 0 4px;
}
.social li a i {
display: table-cell;
vertical-align: middle;
text-align: center;
}
.social li a:hover, .social li a:active, .social li a:focus {
text-decoration: none;
border-bottom: none;
}
.social li a.social-box {
background: #ffc637;
color: #fff;
}
.social li a.social-circle {
background: rgba(24,9,13,1.00);
color: #ffc637;
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
-ms-border-radius: 50%;
border-radius: 50%;
}
.social li a:hover {
background: #42689C !important;
}
.social.social-box a {
background: #FFC637;
color: #fff;
}
.social.social-circle a {
background: #ffc637;
color: #fff;
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
-ms-border-radius: 50%;
border-radius: 50%;
}


footer {
padding: 2em 0;
font-size: 1.0rem;
font-family: "lora-regular", sans-serif;
}
footer a, footer a:visited {
color: #FFFFFF;
}
footer a:hover, footer a:focus {
color: #ffc637;
}
footer .row {
  max-width: 900px;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
@media (max-width:644px){
  footer .row {
      justify-content: center;
  }
}
footer .social {
text-align: right;
}
footer .footer-social {
display: inline-block;
font-size: 2.1rem;
margin: 0;
padding: 0;
position: relative;
top: -.3rem;
}
footer .footer-social li {
display: inline-block;
margin: 0 12px;
padding: 0;
}
footer .footer-social li a {
color: #FFFFFF;
}
footer .copyright span {
font-family: Gotham, Helvetica Neue, Helvetica, Arial," sans-serif";
display: inline-block;
}
footer .copyright span::after {
content: "|";
display: inline-block;
padding: 0 1rem 0 1.2rem;
color: rgba(255, 255, 255, 0.1);
}
footer .copyright span:last-child::after {
display: none;
}

footer .siga {
font-family: Gotham, Helvetica Neue, Helvetica, Arial," sans-serif";
text-align: left;
color: white;
padding: 0px 5px 0px 0px;
}

.zaask_img img{
max-width: 95px;
}
/**
* responsive:
* footer
* -------------------------------------------------------------------
*/
@media (max-width:740px){
footer .row {
  flex-flow: column-reverse;
}
footer .row > * {
  margin-bottom: 20px;
}
}


@media only screen and (max-width:768px) {
footer {
  text-align: center;
}
footer .social {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
}
footer .copyright span {
  display: block;
}
footer .copyright span::after {
  display: none;
}
}